import styled from "styled-components";
import logoBanco from "../../Images/IconTermConditionsSoyYoBank.svg"

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
  disabled: "#D9DADD"
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #2C2A29;
`;
export const Title = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
  color: #2C2A29;
  font-family: "Nunito";
  text-align: center;
  margin-bottom: 20px;
  line-height: 28px;
  font-family: "CIBFont Sans";

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 24px;
  }
`;
export const Conditions = styled.div`
  width: auto;
  height: 415px;
  justify-content: center;
  margin: 0.5rem 30px;
  text-align: justify;

  @media screen and (max-width: 961px) {
    margin: 0.5rem 23px;
    height: 615px;
  }
`;
export const DescriptionText = styled.div`
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 16px;
  text-align: center;
  color: ${colors.secondary};
  width: auto;
  height: auto;
  text-align: start;
`;
export const SubTitle = styled.h1`
  display: flex;
  font-family: "CIBFont Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 26px;
  text-align: center;
  color: ${colors.secondary};
  width: auto;
  height: auto;
  justify-content: left;
  text-align: justify;
`;
export const ImageTaC = styled.div`
  background-image: url(${logoBanco});
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  height: 48px;
`;