import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    flex-direction: column;
  }
`;