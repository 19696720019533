import React, { useEffect, useState } from "react";
import ButtonCloseTemplate from "../ButtonClose/ButtonCloseTemplate"
import ButtonContainerTemplate from "../ButtonContainer/ButtonContainerTemplate"
import Checkbox from "../Checkbox/CheckboxTemplate"
import DynamicScrollbar from '../DynamicScrollbar/DynamicScrollbar';
import { Container, Title, Conditions, DescriptionText, SubTitle, ImageTaC } from "../../Styles/TermsAndConditions/TermsAndConditionsStyle"
import { termsAndConditionsBank, dataPrivacyPolicyBank, termsAndConditionsSoyYo, dataPrivacyPolicySoyYo } from "../../Constants/Constants"
import LogoBank from "../ImageLogo/ImageLogo"

function TermsAndConditions({cancel, acept}) {

  const [disable, setDisable] = useState(true);
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);
  const [checkThree, setCheckThree] = useState(false);
  const [checkFour, setCheckFour] = useState(false);

  useEffect(() => {
    checkOne && checkTwo && checkThree && checkFour ? setDisable(false) : setDisable(true)
  },[checkOne, checkTwo, checkThree, checkFour]);

  const title = "Autorización para uso de datos biométricos"
  const subTitle1 = "¿Qué pasa con tus datos personales?";
  const descriptiontext1 = "Autorizas a Grupo Bancolombia y sus entidades, tanto en Colombia como en el exterior, a tratar tus datos personales y sensibles según la autorización de protección de datos. Necesitamos esta información para identificarte."
  const descriptiontext2 = "Con el fin de crear mi identidad digital y prevenir el fraude por suplantación, autorizo a SoyYo de Redeban a usar la foto de mi cara y a tratar mis datos de acuerdo con sus términos y condiciones y su política de privacidad. Entiendo que esta aprobación no es obligatoria y que podré renovarla cuando yo quiera, pero sin ella no podré continuar";
  const textCheckbox1 = "Aceptar ";
  const textCheckbox2 = "Aceptar ";
  const textCheckbox3 = "Aceptar ";
  const textCheckbox4 = "Aceptar ";
  const textLink1 = "Política de privacidad de datos"
  const textLink2 = "Términos y condiciones Bancolombia"
  const textLink3 = "Política de privacidad de datos"
  const textLink4 = "Términos y condiciones SoyYo de Redeban"

  return (
    <Container>
      <ButtonCloseTemplate cancel={cancel}/>
      <LogoBank marginTop={"22px"} marginBottom={"23px"}/>
      <DynamicScrollbar>
        <ImageTaC/>
        <Conditions>
        <Title>{title}</Title>
          <SubTitle>{subTitle1}</SubTitle>
          <DescriptionText>{descriptiontext1}</DescriptionText>
          <Checkbox text={textCheckbox1} check={setCheckOne} textLink={textLink1} url={dataPrivacyPolicyBank}/>
          <Checkbox text={textCheckbox2} check={setCheckTwo} textLink={textLink2} url={termsAndConditionsBank}/>
          <DescriptionText>{descriptiontext2}</DescriptionText>
          <Checkbox text={textCheckbox3} check={setCheckThree} textLink={textLink3} url={dataPrivacyPolicySoyYo}/>
          <Checkbox text={textCheckbox4} check={setCheckFour} textLink={textLink4} url={termsAndConditionsSoyYo}/>
          <ButtonContainerTemplate acept={acept} reject={cancel} isDisable={disable} />
          </Conditions>
      </DynamicScrollbar>
    </Container>
  );
}
export default TermsAndConditions;