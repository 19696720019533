import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { thumbVerticalStyle, thumbStyleTrack } from "../../Styles/DynamicScrollbar/DynamicScrollbarStyle"

function DynamicScrollbar(props) {

  const renderThumbVertical = (props, style) => {
    const thumbStyleWithColor  = { ...style, ...thumbVerticalStyle };
    return <div {...props} style={thumbStyleWithColor} />;
  };

  const renderTrackVertical = (props, style) => {
    const thumbStyleWidth = { ...style, ...thumbStyleTrack };
    return <div {...props} style={thumbStyleWidth} />;
  }

  return (
    <Scrollbars 
      universal
      autoHeight 
      autoHeightMax={615} 
      renderThumbVertical={renderThumbVertical}
      renderTrackVertical={renderTrackVertical}
    >
      {props.children}
    </Scrollbars>
  );
}

export default DynamicScrollbar;